import './App.css';
import Herobs from './components/heroesbs/Herobs';
import Navbs from './components/navbs/Navbs';
import Services from './components/services/Services';

import service_design from "./images/Online page-bro.png"
import service_SEO from "./images/SEO.png"
import service_maintenance from "./images/Maintenance.png"
import service_consulting from "./images/Consulting.png"
import service_responsive from "./images/Responsive.png"
import service_optimization from "./images/optimization.png"


import Contact from './components/contact/Contact';
import Footerbs from './components/footer/Footerbs';
import ActionBtn from './components/ActionBT/ActionBt';

// import { BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';






function App() {
  
  return (
    <div className="App" >

        <span id='inicio'></span>
        <Navbs></Navbs>
        <ActionBtn/>

        <Herobs></Herobs>
        <span id='servicios'></span>
        <div className='servicios'>
          <p className='ServicesTitle'>Servicios</p>
          <Services 
            image = {service_design}
            title = 'Diseño y Desarrollo de Sitios Web'
            text  = 'Servicios completos de diseño y desarrollo de sitios web personalizados para empresas y particulares.'
            item1 = 'Diseño personalizado'
            item2 = 'Desarrollo utilizando tecnologías modernas'
            item3 = 'Enfoque en usabilidad y experiencia del usuario'

            infotitle1 = 'Diseño personalizado y único'
            infotext1 = 'Creamos diseños únicos y a medida que se adaptan a las necesidades específicas de cada cliente, asegurando una presencia web distintiva y atractiva.'

            infotitle2 = 'Desarrollo utilizando tecnologías modernas'
            infotext2 = 'Utilizamos tecnologías de vanguardia y las mejores prácticas de desarrollo web para garantizar que los sitios sean eficientes, seguros y escalables.'

            infotitle3 = 'Enfoque en usabilidad y experiencia del usuario'
            infotext3 = "Ponemos énfasis en la facilidad de uso y la experiencia del usuario, asegurando que la navegación sea intuitiva y que los visitantes encuentren lo que están buscando de manera rápida y efectiva."
            contractText = "Hola, estoy interesado en obtener servicios de diseño y desarrollo de sitios web. ¿Podrían proporcionarme más información? Muchas gracias"
          />
          <Services 
            image = {service_SEO}
            title = 'Optimización para Motores de Búsqueda (SEO)'
            text  =' Impulsa la visibilidad de tu sitio web en los motores de búsqueda con servicios profesionales de optimización SEO.'
            item1 = 'Análisis exhaustivo de palabras clave y competencia'
            item2 =' Optimización técnica del sitio'
            item3 = 'Creación de contenido relevante y de calidad'
            
            infotitle1 =' Análisis exhaustivo de palabras clave y competencia'
            infotext1 = 'Realizamos un análisis exhaustivo de las palabras clave relevantes para tu negocio y de la competencia, identificando oportunidades y áreas de mejora para mejorar tu posicionamiento en los motores de búsqueda.'
            
            infotitle2 =' Optimización técnica del sitio'
            infotext2 = 'Optimizamos técnicamente tu sitio web para mejorar su velocidad de carga, estructura de URL y otros aspectos técnicos que influyen en su visibilidad en los motores de búsqueda.'
            
            infotitle3 =' Creación de contenido relevante y de calidad'
            infotext3 = 'Creamos contenido relevante, informativo y de calidad que no solo atraiga a los motores de búsqueda, sino que también mantenga comprometidos a tus visitantes y los convierta en clientes potenciales.'
            contractText = "Hola, estoy buscando mejorar la visibilidad de mi sitio web en los motores de búsqueda. ¿Podrían proporcionarme más información? Muchas gracias"
            
          />

          <Services 
            image = {service_maintenance}
            title = 'Mantenimiento y Soporte'
            text = 'Servicios de mantenimiento continuo y soporte técnico para garantizar que tus sitios web estén actualizados, seguros y funcionen sin problemas.'
            item1 = 'Actualizaciones para garantizar la estabilidad del sitio'
            item2 = 'Monitoreo para detectar y solucionar problemas'
            item3 = 'Soporte técnico rápido y eficiente'
            
            infotitle1 = 'Actualizaciones regulares'
            infotext1 = 'Realizamos actualizaciones regulares del software y plugins del sitio para garantizar que estén protegidos contra vulnerabilidades de seguridad y funcionen correctamente.'
            
            infotitle2 = 'Monitoreo proactivo'
            infotext2 = 'Monitorizamos el sitio web de forma proactiva para detectar cualquier problema de rendimiento o seguridad, y tomo medidas rápidas para solucionarlo antes de que afecte a la experiencia del usuario.'
            
            infotitle3 = 'Soporte técnico rápido'
            infotext3 = 'Ofrecemos soporte técnico rápido y eficiente para resolver cualquier problema técnico que pueda surgir, asegurando que tu sitio web esté siempre disponible y funcione sin problemas.'
            contractText = "Buen día, necesito servicios de mantenimiento y soporte para mi sitio web. ¿Podrían proporcionarme más información sobre los servicios que ofrecen en este sentido? Muchas gracias"
            
          />
          <Services
            image = {service_consulting}
            title = 'Consultoría y Asesoramiento'
            text = 'Brindamos orientación y asesoramiento experto sobre estrategias web, mejores prácticas y soluciones tecnológicas.'
            item1 = 'Análisis detallado de las necesidades y objetivos del cliente'
            item2 =' Recomendaciones personalizadas basadas en las últimas tendencias'
            item3 =' Asesoramiento continuo y seguimiento para garantizar el éxito'

            infotitle1 = 'Análisis detallado'
            infotext1 = 'Realizamos un análisis detallado de las necesidades y objetivos de cada cliente para comprender completamente sus requisitos y proporcionar soluciones personalizadas.'

            infotitle2 = 'Recomendaciones personalizadas'
            infotext2 = 'Ofrecemos recomendaciones personalizadas basadas en las últimas tendencias y tecnologías del mercado, adaptadas específicamente a las necesidades de cada cliente.'

            infotitle3 = 'Asesoramiento continuo'
            infotext3 =' Proporcionamos asesoramiento continuo y seguimiento para garantizar el éxito a largo plazo de los proyectos, manteniéndome disponible para responder preguntas y proporcionar orientación en cualquier momento.'
            contractText = "Hola, estoy buscando orientación y asesoramiento experto en estrategias web y tecnologías digitales. ¿Podrían proporcionarme más información? Muchas gracias"

          />

          <Services
            image = {service_responsive}
            title = 'Diseño Responsivo y Adaptabilidad Móvil'
            text = 'Aseguramos que tu sitio web se vea y funcione de manera excelente en todos los dispositivos.'
            item1 = 'Diseño fluido que se adapta a diferentes tamaños de pantalla'
            item2 =' Creación de navegación intuitiva y fácil de usar'
            item3 = 'Optimización para mejorar la experiencia del usuario móvil'
            
            infotitle1 = 'Diseño fluido'
            infotext1 =' Utilizamos técnicas de diseño responsivo para crear un diseño fluido que se ajusta automáticamente al tamaño de la pantalla del dispositivo, garantizando una experiencia visualmente atractiva y coherente en todos los dispositivos.'
            
            infotitle2 =' Navegación intuitiva'
            infotext2 =' Diseñamos la navegación del sitio de manera intuitiva y fácil de usar en dispositivos táctiles, asegurando que los usuarios puedan acceder fácilmente al contenido y navegar por el sitio sin problemas, independientemente del dispositivo que estén utilizando.'
            
            infotitle3 =' Optimización de velocidad de carga'
            infotext3 = 'Implementamos técnicas de optimización de velocidad de carga para garantizar tiempos de carga rápidos en dispositivos móviles, lo que mejora la experiencia del usuario y reduce las tasas de abandono del sitio.'
            contractText = "Hola, necesito asegurarme de que mi sitio web sea compatible con dispositivos móviles y tenga un diseño responsivo. ¿Podrían proporcionarme más información? Muchas gracias"
            
            
          />

          <Services
            image = {service_optimization}
            title = 'Optimización'
            text = 'Mejoramos la velocidad de carga y el rendimiento general de tu sitio web para proporcionar una mejor experiencia al usuario.'
            item1 = 'Análisis exhaustivo de rendimiento para identificar cuellos de botella.'
            item2 = 'Técnicas de compresión de archivos y optimización de recursos.'
            item3 = 'Mejorar la velocidad de entrega del contenido.'

            infotitle1 = 'Análisis exhaustivo de rendimiento'
            infotext1 = 'Realizamos un análisis detallado del rendimiento del sitio web para identificar áreas que requieren mejoras, como tiempos de carga lentos o recursos excesivos, con el objetivo de optimizar la experiencia del usuario.'
            
            infotitle2 = 'Implementación de técnicas de compresión'
            infotext2 = 'Implementamos técnicas de compresión de archivos y optimización de recursos, como la minificación de archivos CSS y JavaScript, para reducir el tamaño de los archivos descargados y mejorar los tiempos de carga del sitio web.'
            
            infotitle3 = 'Uso de caché y CDN'
            infotext3 =' Utilizamos la caché del navegador y una CDN para almacenar en caché contenido estático y distribuirlo desde servidores ubicados estratégicamente en todo el mundo, lo que reduce la latencia y mejora la velocidad de entrega del contenido a los usuarios finales.'
            contractText = "Hola, estoy buscando mejorar el rendimiento de mi sitio web. ¿Podrían proporcionarme más información? Muchas gracias"
            
          />
        </div>
          

        <div className='contacto'>
          <span id='contacto'></span>
          <p className='ContactoTitle'>Contacto</p>
        </div>
        <Contact></Contact>
        <span id='foot'></span>
        <Footerbs></Footerbs>

        
    </div>
        
  );
}

export default App;

