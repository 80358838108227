import './App.css';

import App from './App';


function ReactApp() {
  return (
    <div>
        <App/>
    </div>
  )
}

export default ReactApp