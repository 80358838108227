import React from 'react'
import "./Foot.css"
import Logo from "../../images/logoInova.png"
import Logo2 from "../../images/inova.png"



function Footerbs() {
  return (
    <div className='footerCustom '>
      <ul class="nav justify-content-center pb-2 mt-0">
        <li class="nav-item"><a href="#inicio" class="nav-link    px-2     ancorcustom" >Inicio</a></li>
        <li class="nav-item"><a href="#servicios" class="nav-link px-2 ancorcustom" >Servicios</a></li>
        <li class="nav-item"><a href="#contacto" class="nav-link  px-2   ancorcustom" >Contacto</a></li>
      </ul>
      <div className='text-center'>
        <img src={Logo}  alt="" width="60" href="#inicio" style={{padding:"10px", filter:"opacity(.3) grayscale(1) brightness(2)"}} />
        <img src={Logo2} alt="" width="60" href="#inicio" style={{padding:"10px", filter:"opacity(.3) grayscale(1) brightness(2)"}} />
      </div>

      <footer className="py-3">
        <p className="text-center text-secondary">© 2023 Inova Studios, Inc</p>
      </footer>
    </div>
  )
}

export default Footerbs
