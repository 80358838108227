import React, { useState } from 'react';
import "./Services.css"
import expandir from "../../images/entrarfill.png"
import llamar from "../../images/contactfill.png"




const Services  = (props) => {
    let { 
        image, title, text, 
        item1, item2, item3, 
        infotitle1, infotext1,
        infotitle2, infotext2,
        infotitle3, infotext3,
        contractText
    } = props;


    const [infostate,setinfostate] = useState(false);

    function showinfo() {
        setinfostate(!infostate)
    }

    function contract() {
        let text =  document.querySelector(".form-textCustom")
        text.value = contractText;
        setinfostate(!infostate)
    }

    

    return (
        <div style={{padding:"20px"}}>
            <div style={{textAlign:"center"}} >
                <div className="card text-bg-dark serviceCard" style={{width: "20rem"}}>
                    <img src={image} style={{paddingInline:"20px"}} className="card-img-top" alt="..."/>
                    <div className="card-body  ">
                        <h5 className="card-title" style={{fontFamily:"Nexa", marginTop:"10%"}}>{title}</h5>
                        <p className="card-text">{text}</p>
                    </div>
                    <ul className="list-group list-group-flush border-success ">
                        <li className="list-group-item text-bg-dark border-success serviceitem">{item1}</li>
                        <li className="list-group-item text-bg-dark border-success serviceitem">{item2}</li>
                        <li className="list-group-item text-bg-dark border-success serviceitem">{item3}</li>
                    </ul>
                    <div className="card-body">
                        <a className="card-link"><img className='iconos' src={expandir} alt="" onClick={showinfo}/></a>
                        <a className="card-link" href='#contacto' onClick={() => {let text =  document.querySelector(".form-textCustom");text.value = contractText;}}><img className='iconos' src={llamar} alt="" /></a>
                    </div>
                </div>
            </div>

            <div className={infostate ? "activeinfo" : "disabledinfo"}>
                <div className="modal modal-sheet position-fixed  d-block p-4 py-md-5 customBG"  tabindex="-1" role="dialog" id="modalTour">
                    <div className="modal-dialog " role="document">
                        <div className="modal-content rounded-4 shadow custom2BG">
                        <div className="modal-body p-5">
                            <h2 className="fw-bold mb-0">{title}</h2>

                            <ul className="d-grid gap-4 my-5 list-unstyled small">
                            <li className="d-flex gap-4">
                                <div>
                                <h5 className="mb-0">{infotitle1}</h5>
                                {infotext1}
                                </div>
                            </li>
                            <li className="d-flex gap-4">
                                <div>
                                <h5 className="mb-0">{infotitle2}</h5>
                                {infotext2}
                                </div>
                            </li>
                            <li className="d-flex gap-4">
                                <div>
                                <h5 className="mb-0">{infotitle3}</h5>
                                {infotext3}
                                </div>
                            </li>
                            </ul>
                            <a href="#contacto"><button type="button" className="btn btn-lg btn-success mt-5 w-100"  data-bs-dismiss="modal" onClick={contract}>Contratar</button></a>
                            <button type="button" className="btn btn-lg btn-secondary mt-3 w-100" data-bs-dismiss="modal" onClick={showinfo}>Cerrar</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
  )
}

export default Services