import React from 'react'
import heroeimage from "../../images/Static website-amico.png"
import "./style.css"
import Logo from "../../images/logoInova.png"


function Herobs() {
  function contact() {
    var button = document.querySelector(".buttonContacto")
    setTimeout(() => {
      button.style.background = "var(--transparent)";
      button.style.color = "var(--whitecolor)"
    }, 200); 
    button.style.color = "var(--blackcolor)"
    button.style.background = "var(--whitecolor)";

  }
  return (
    <div>
      <div className= 'HeroDiv' >
          <div className='fondo'></div>
          <div className='fondo2'></div>
          <img src= { heroeimage } alt="image" className='heroimage'/>
          
          <h1 className='title'>Tu Pagina Web con<p className='inovatitle'>Inova</p><img src= { Logo } alt="image" className='heroLogo'/></h1>
      </div>
      <div className= 'ButtonDiv'>
        <a href="#contacto"><button className='buttonContacto' onClick={contact}>Contacto</button></a>
      </div>
    </div>

  )
}

export default Herobs