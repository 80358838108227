import React from 'react'
import Logo from "../../images/logoInova.png"
import Logo2 from "../../images/inova.png"
import "./Navbs.css"
import { useState } from 'react'
import Theme from '../theme/Theme'

// import { Link} from 'react-router-dom';

function Navbs() {

  const [homeState,     sethomeState]      = useState(true)
  const [servicesState, setservicesState]  = useState(false)
  const [contactState,  setcontactState]   = useState(false)
  const [aboutState,    setaboutState]     = useState(false)

  var navStates = [sethomeState,setservicesState,setcontactState,setaboutState]

  function changeSection(key) {
    for (let index = 0; index < navStates.length; index++) { 
      navStates[index](false) 
    }

    switch (key) {
      case 1:
        sethomeState(true)
        break;
      case 2:
        setservicesState(true)
        break;
      case 3:
        setcontactState(true)
        break;
      case 4:
        setaboutState(true)
        break;
    }
    
  }

  function handleClick() {
    window.location.href = "#inicio";
  };


  return (
    <div>
      <nav className="navbar navbar-expand-lg fixed-top" data-bs-theme="dark" style={{background : "var(--blackcolor)"}} >
        <div className="container-fluid " >
          <a className='ActiveBrand'>
          <img src={Logo2} alt="" width="50" className='logo2' onClick={handleClick} style={{cursor:"pointer"}} />
          <img src={Logo} alt="" width="50" className='logo1 ' onClick={handleClick} style={{cursor:"pointer"}} />
          <Theme></Theme>
          </a>
          <button className="navbar custombutton" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown" style={{justifyContent:"center"}}>
            <ul className="navbar-nav">
              <li className="nav-item" >
                <a onClick={() => changeSection(1)} className= {homeState ? "nav-link Active": "nav-link"} aria-current="page" href="#inicio">Inicio</a>
              </li>
              <li className="nav-item">
                <a onClick={() => changeSection(2)} className= {servicesState ? "nav-link Active": "nav-link"} href="#servicios">Servicios</a>
              </li>
              <li className="nav-item">
                <a onClick={() => changeSection(3)} className= {contactState ? "nav-link Active": "nav-link"} href="#contacto">Contacto</a>
              </li>
              {/*<li className="nav-item dropdown">
                <a onClick={() => changeSection(4)} className= {aboutState ? "nav-link dropdown-toggle Active": "nav-link dropdown-toggle"}  href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Sobre Nosotros
                </a>
                <ul className="dropdown-menu" style={{background : "#101010"}}>
                  <li><Link className="dropdown-item" to={"/nosotros"}>Nuestra Empresa</Link></li>
                  <li><Link className="dropdown-item" to={"/"}>Misión</Link></li>
                  <li><Link className="dropdown-item" to={"/"}>Visión</Link></li>
                </ul>
              </li>*/}
            </ul>
          </div>
        </div>
        
      </nav>
    </div>
  )
}

export default Navbs