import React from 'react'
import "./ActionBt.css"
import logo from "../../images/whatsapp.png"
function ActionBtn() {
    function goToChat() {
        if ( window.innerWidth >= 850){
            var telefono = '1133171170';
        } else {
            var telefono = "33171170"
        }
        var url = 'https://wa.me/'+telefono;
        window.open(url, '_blank');
        
    }
    return(
        <div className='Circle' onClick={goToChat}>
            <img src={logo} alt="wpp" className='icoWpp'/>
        </div>
    )
}

export default ActionBtn