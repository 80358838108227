import React, { useState } from 'react'
import "./Theme.css"



function Theme() {


    function ThemeLight() {
        const divlight = document.querySelector(".themelight");
        divlight.style.filter = "opacity(1)";

        const itemP = document.querySelector(".ThemeButton");
        itemP.style.background = "#6990a7";

        const item = document.querySelector(".themeitem1");
        item.style.transform = "translate(30px)";
        item.style.background = "#eca41f";
        item.style.border =  "2px solid #ecbd22";




        const root = document.documentElement;
        const cards = document.querySelectorAll(".serviceCard");
        
        for (let card = 0; card < cards.length; card++) {
            cards[card].classList.remove('text-bg-dark');
            cards[card].classList.add('text-bg-light');
            
        }
        

        const items = document.querySelectorAll(".border-success");

        for (let item = 0; item < items.length; item++) {
            items[item].classList.remove('text-bg-dark');
            items[item].classList.remove('border-success');
            items[item].classList.add('text-bg-light');
            items[item].classList.add('border');
            
        }


        root.style.setProperty('--firstcolor'     , '#00ffa9');
        root.style.setProperty('--blackcolor'     , '#1e262e');
        root.style.setProperty('--graycolor'      , '#bebebe');
        root.style.setProperty('--backgroundbody' , '#dbdbdb');
        root.style.setProperty('--graybluecolor'  , '#1f2d38');
        root.style.setProperty('--whitecolor'     , '#475263');
        root.style.setProperty('--inmutable-whiteblack', '#e2e2e2');
        root.style.setProperty('--inmutable-blackwhite', '#191919');

        const divdark = document.querySelector(".themedark");
        divdark.style.filter = "opacity(0)";

        
    }

    function ThemeDark() {
        const itemP = document.querySelector(".ThemeButton");
        itemP.style.background = "#24272b";

        const item = document.querySelector(".themeitem1");
        item.style.transform = "translate(0px)";
        item.style.background = "#b9b9b9";
        item.style.border =  "2px solid #94948b";

        const divdark = document.querySelector(".themedark");
        divdark.style.filter = "opacity(1)";


        const root = document.documentElement;
        const cards = document.querySelectorAll(".serviceCard");
        
        for (let card = 0; card < cards.length; card++) {
            cards[card].classList.remove('text-bg-light');
            cards[card].classList.add('text-bg-dark');
            
        }
        

        const items = document.querySelectorAll(".border");

        for (let item = 0; item < items.length; item++) {
            items[item].classList.remove('text-bg-light');
            items[item].classList.remove('border');
            items[item].classList.add('text-bg-dark');
            items[item].classList.add('border-success');
            
        }
        root.style.setProperty('--firstcolor'     , '#00ffa9');
        root.style.setProperty('--blackcolor'     , '#191919');
        root.style.setProperty('--graycolor'      , '#313131');
        root.style.setProperty('--backgroundbody' , '#1b1b1b');
        root.style.setProperty('--graybluecolor'  , '#24272b');
        root.style.setProperty('--whitecolor'     , '#e2e2e2');
        root.style.setProperty('--inmutable-whiteblack', '#191919');
        root.style.setProperty('--inmutable-blackwhite', '#e2e2e2');

        const divlight = document.querySelector(".themelight");
        divlight.style.filter = "opacity(0)";

    }
    

    const [ThemeState, setThemeState] = useState(false)

    function changeTheme() {
        if (ThemeState) {setThemeState(false)}
        else {setThemeState(true)}

        switch (ThemeState) {
            case true:
                ThemeDark()
                break;
        
            case false:
                ThemeLight()
                break;
        }
    }

  return (
    <div>
        <div className='ThemeButton' onClick={changeTheme}>
            <div className='themeitem1'></div>
            <div className='themedark'>
                <span className='star1' >✦</span>
                <span className='star2' >✦</span>
                <span className='star3' >✦</span>
            </div>

            <div className='themelight'>
                <span className='cloud1'></span>
                <span className='cloud2'></span>
                <span className='cloud3'></span>
                <span className='cloud4'></span>
                <span className='cloud5'></span>
                <span className='cloud6'></span>
            </div>
        </div>
    </div>
  )
}

export default Theme