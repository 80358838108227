import React from 'react'
import "./Contact.css"
import { useState } from 'react'
import axios from 'axios';


import checkLogo   from "../../images/cheque.png"
import errorLogo   from "../../images/error.png"
import warningLogo from "../../images/advertencia.png"
import closeLogo   from "../../images/cerrar.png" 



function Contact() {

    const [alertState,setAlertState] = useState(false)

    const [alertUI,setAlertUI] = useState("Success")

    const [AlertContent, setAlertContent] = useState({
        imagen  : "",
        title   : "",
        content : "",
    })



    const [dataForm,setdataForm] = useState({
        nombre:"", email:"",asunto:"",mensaje:""
    })



    const check = async (key) => {
        console.clear()
        setAlertState(false)
        
        key.preventDefault();
        let testing = /^[\w-]/;
        let success = true;

        let form = [
            dataForm.nombre,
            dataForm.email,
            dataForm.asunto,
            dataForm.mensaje
        ]

        


        setTimeout(async function() {
            try {
                for (let index = 0; index < form.length; index++) {
                    let result = testing.test(form[index]);
                    if (!result) {
                        throw new Error({message:'xd'});      
                    }  
                } 
                
            } catch (error) {
                console.error("formulario completo : Error");
                success = false
            }
            
            
            if (success){
                console.warn("formulario completo : ok");
                let validacionEmail = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/
                let mailvalidado = (validacionEmail.test(dataForm.email))
    
                if (!mailvalidado){ 
                    console.error("Sintaxis Email : Error")
                    setAlertUI("Warning")
                    setAlertContent({imagen:warningLogo,title:"Advertencia",content:"El email ingresado es inválido. Ingresa un email valido para poder contactarte!"})
                    setAlertState(true)
                }
                else {
                    console.warn("Sintaxis Email : ok")
                    key.preventDefault();
                    try {
                        await axios.post('https://formspree.io/f/xpzvpvoo', dataForm);
                        // Manejar la respuesta del servidor si es necesario (ej. mostrar mensaje de éxito)
                        console.log('Formulario enviado con éxito');
                        setAlertUI("Success")
                        setAlertContent({imagen:checkLogo,title:"Enviado con éxito",content:"Recibimos tu mensaje! Te contactaremos pronto."})
                        setAlertState(true)
                    } catch (error) {
                        // Manejar errores (ej. mostrar mensaje de error)
                        console.error('Error al enviar el formulario:', error);
                        setAlertUI("Error")
                        setAlertContent({imagen:checkLogo,title:"Error al enviar",content:"Ocurrio un error inesperado. Intente nuevamente o contactenos por WhatsApp."})
                        setAlertState(true)
                    }
                    
                }
            } else {
                setAlertUI("Error")
                setAlertContent({imagen:errorLogo,title:"Error al enviar",content:"Hay casillas vacias"})
                setAlertState(true)
            }
          }, 500);



        
    }

    function close(){
        setAlertState(false)
    }




    const handleChange = (val) => {
        setdataForm({
            ...dataForm,
            [val.target.name]: val.target.value,

        });
    };




    const [buttonActive,setButtonActive] = useState(false)

    function buttonState(){
        setButtonActive(true)
        setTimeout(() => {
            setButtonActive(false)
        }, 500);
    }

  return (
    <div className='FormContain '>
        <form onSubmit={check} className='formcontact'>
            <div className="mb-3 ">
                <input type="text" className="form-controlCustom " value={dataForm.nombre} name='nombre' onChange={handleChange} placeholder='Nombre'/>
                <label className="form-labelCustom"> Nombre</label>
            </div>
            <div className="mb-3">
                <input type="text
            " className="form-controlCustom" value={dataForm.email} name='email' onChange={handleChange}  placeholder='@'/>
                <label className="form-labelCustom">Email</label>
            </div>
            
            <div className="mb-3">
                <input type="text" className="form-controlCustom" value={dataForm.asunto} name='asunto' onChange={handleChange}  placeholder='Asunto'/>
                <label className="form-labelCustom">Asunto</label>
            </div>
            <div className="mb-3">
                <textarea  className="form-textCustom" value={dataForm.mensaje} name='mensaje' onChange={handleChange}  placeholder='Envianos un mensaje'></textarea>
            </div>
            <div className='ButtonContain'>
                <button className={buttonActive ? "btn-submit-active":"btn-submit"} type='submit' onClick={buttonState} >Enviar</button>
            </div>

        </form> 
        <div className= {alertState ? "TContCustom":"TContCustomHide"} id='Alerts'>
            <div className={alertUI === "Success" ? "toastCustomSuccess" : alertUI === "Warning" ? "toastCustomWarning" : "toastCustomError"} role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-header">
                <img src={AlertContent.imagen} className="rounded me-2" alt="..."/>
                <strong className="me-auto">{AlertContent.title}</strong>
                <button type="button" aria-label="Close" onClick={close} style={{cursor:"pointer",background:"transparent",border:"0"}}><img src={closeLogo} width={12}></img></button>
                </div>
                <div className="toast-body" style={{paddingLeft:"30px"}}>
                {AlertContent.content}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact